<div *ngIf="!dataService.currentItem" class="grid grid-cols-2 gap-0">
  <div class="flex items-center justify-left h-full bg-green-600">
      <app-search></app-search>
  </div>

  <div class="flex items-right justify-end  h-full bg-green-600">
    <p class=" text-gray-400 dark:text-gray-500 text-2xl pr-2">
      <app-links></app-links>
    </p>
  </div>

</div>

<app-items *ngIf="!dataService.currentItem"></app-items>
<app-item *ngIf="dataService.currentItem"></app-item>




