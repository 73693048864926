import { Component, OnInit } from '@angular/core';
import { AccountService, ItemsService, ConfigService } from '@app/_services';
import { initFlowbite } from 'flowbite';
import { Datepicker } from 'flowbite';
import type { DatepickerOptions, DatepickerInterface, ModalOptions, ModalInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import Swal from 'sweetalert2';
import { Modal } from 'flowbite';
import { Dropdown } from 'flowbite';
import type { DropdownOptions, DropdownInterface } from 'flowbite';


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit{
  fileName = "";
  file:any = "";
  validationError="";
  currentCategory = "All Categories";
  account = this.accountService.accountValue;
  placeholder = "https://placehold.co/600x400/png?text=sample";
  item = {
    "id":0,
    "itemid":"",
    "title":"",
    "category":"",
    "categoryid":0,
    "description":"",
    "value":0,
    "startingbid": 0,
    "buynow": 0,
    "image":this.placeholder
  };
  items:any = [];
  filtereditems:any = [];
  categories:any = [];

  dropdown: DropdownInterface|any;

  constructor(
    private accountService: AccountService,
    private itemsService: ItemsService,
    private configService: ConfigService
  )
  {
    setTimeout(() => initFlowbite(), 1500);
  }

  ngOnInit(): void {


    this.itemsService.getItems().pipe()
    .subscribe({
        next: (data:any) => {
          this.items = data;
          this.filtereditems = data;
        }
      })

    this.configService.getCategories().pipe()
    .subscribe({
        next: (data:any) => {
          this.categories = data;
        }
      })


      // set the dropdown menu element
      const $targetEl: HTMLElement|any = document.getElementById('dropdownMenu');

      // set the element that trigger the dropdown menu on click
      const $triggerEl: HTMLElement|any = document.getElementById('dropdownButton');
      //const $modalElement: any = document.querySelector('#modalEdit');
      // options with default values
      const options: DropdownOptions = {
        placement: 'bottom',
        triggerType: 'click',
        offsetSkidding: 0,
        offsetDistance: 10,
        delay: 300

      };

      // instance options object
      const instanceOptions: InstanceOptions = {
        id: 'dropdownMenu',
        override: true
      };

      this.dropdown = new Dropdown(
        $targetEl,
        $triggerEl,
        options,
        instanceOptions
      );

      // show the dropdown
      //this.dropdown.show();

  }

  filter(type: any, obj: any) {

    this.currentCategory = type;

    if (this.currentCategory=='All Categories') {
      this.filtereditems = this.items;
    } else {
      this.filtereditems = this.items.filter((i:any) => i.category == this.currentCategory);
    }

    this.dropdown.hide();
  }





  addInventory() {
    this.validationError = "";
    if (this.item.itemid=="") {
      this.validationError = "Item Id is required"
      return;
    }

    if (this.item.title=="") {
      this.validationError = "Title is required"
      return;
    }

    if (this.item.description=="") {
      this.validationError = "Description is required"
      return;
    }


    var obj = {
      "itemid":this.item.itemid,
      "image": "https://s3.amazonaws.com/app.silentauction.v1/" + this.fileName,
      "title": this.item.title,
      "categoryid": this.item.categoryid,
      "description": this.item.description,
      "value": this.item.value,
      "startingbid": this.item.startingbid,
      "buynow": this.item.buynow,
      "available":1,
      "status":"available"
    }

    this.itemsService.addItem(obj).pipe()
      .subscribe({
        next: (data:any) => {
          console.log(data);
          window.location.reload();
        }
      })

    this.itemsService.uploaditem(this.file).pipe()
      .subscribe({
        next: (data:any) => {
          console.log(data);
          window.location.reload();
        }
      })



  }

  saveInventory() {
    debugger;
    this.validationError = "";
    if (this.item.itemid=="") {
      this.validationError = "Item Id is required"
      return;
    }

    if (this.item.title=="") {
      this.validationError = "Title is required"
      return;
    }

    if (this.item.description=="") {
      this.validationError = "Description is required"
      return;
    }



    var obj = {
      "itemid":this.item.itemid,
      "title": this.item.title,
      "categoryid": this.item.categoryid,
      "description": this.item.description,
      "value": this.item.value,
      "startingbid": this.item.startingbid,
      "available":1,
      "buynow": this.item.buynow,
      "image": this.item.image,
      "status":"available"
    }

    if (this.fileName) {
      obj.image = "https://s3.amazonaws.com/app.silentauction.v1/" + this.fileName
    }

    this.itemsService.updateItem(this.item.id, obj).pipe()
      .subscribe({
        next: (data:any) => {
          console.log(data);
          window.location.reload();
        }
      })

    if (this.fileName) {

      this.itemsService.uploaditem(this.file).pipe()
        .subscribe({
          next: (data:any) => {
            console.log(data);
            window.location.reload();
          }
        })
      }



  }


  loadFile(obj:any) {
    debugger;
    var input = obj.target;
    this.file = input.files[0];
    var type = this.file.type;

    var output:any = document.getElementById('preview_img');


    this.fileName = this.file.name;



    output.src = URL.createObjectURL(obj.target.files[0]);
    output.onload = function() {
        URL.revokeObjectURL(output.src) // free memory
    }

  }



  onChange(obj: any) {
    this.item.categoryid = obj.target.value;
  }


  editInventory(obj:any) {
    this.item = obj;

    console.log(this.item);


    return;


  }

  deleteInventory(obj:any) {

    Swal.fire({
      title: 'Are you sure you want to delete this item?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.itemsService.deleteItem(obj.id).pipe()
        .subscribe({
            next: (data:any) => {
              window.location.reload();
            }
          })


      } else  {
        //Swal.fire('Changes are not saved', '', 'info')

      }
    })


  }


}



