import { Component, OnInit } from '@angular/core';
import { AccountService, DataService, ConfigService } from '@app/_services';
import { Dropdown, DropdownInterface, DropdownOptions, InstanceOptions, initFlowbite } from 'flowbite';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit{
  account = this.accountService.accountValue;

  artSelection = false;
  furnitureSelection = true;
  miscSelection = false;
  currentCategories:any = [];
  categories:any = [];



  constructor(
    private accountService: AccountService,
    private configService: ConfigService,
    private dataService: DataService
  )
  {

  }

  ngOnInit(): void {
    initFlowbite();


    this.configService.getCategories().pipe()
    .subscribe({
        next: (data:any) => {
          this.categories = data;
          debugger;
        }
      })

      // set the dropdown menu element
      const $targetEl: HTMLElement|any = document.getElementById('dropdownDefaultCheckbox');

      // set the element that trigger the dropdown menu on click
      const $triggerEl: HTMLElement|any = document.getElementById('dropdownCheckboxButton');
      //const $modalElement: any = document.querySelector('#modalEdit');
      // options with default values
      const options: DropdownOptions = {
        placement: 'bottom',
        triggerType: 'click',
        offsetSkidding: 0,
        offsetDistance: 10,
        delay: 300

      };

      // instance options object
      const instanceOptions: InstanceOptions = {
        id: 'dropdownMenu',
        override: true
      };

      this.dataService.dropdown = new Dropdown(
        $targetEl,
        $triggerEl,
        options,
        instanceOptions
      );
}

  filter(type: any, obj: any) {


    if (obj.srcElement.checked) {
      this.currentCategories.push(type);
    } else {
      this.currentCategories = this.currentCategories.filter((t:any) => t != type);
    }

    console.log(this.currentCategories);

    //this.currentCategory = type;

    if (this.currentCategories.county==0) {
      this.dataService.filtereditems = this.dataService.items;
    } else {
      //this.dataService.filtereditems = this.dataService.items.filter((i:any) => i.category == this.currentCategory);
      //this.dataService.filtereditems = this.dataService.items.flatMap(({assigned, name}) => assigned ? [name] : [])

      var that = this;
      var arr = this.dataService.items.filter(
        function(e:any) {
           return that.currentCategories.indexOf(e.category) >= 0;
        }

     );

     console.log(arr);

     this.dataService.filtereditems = arr;

    //  this.dataService.filtereditems = filterArray();


    }




    this.dataService.dropdown.hide();
  }




/*
    oldfilter(type: any, obj: any) {

      console.log(type);
      console.log(obj.srcElement.checked);

      this.dataService.items = [];

      if(type==="art" && obj.srcElement.checked) {
        this.artSelection = true;
      }
      if(type==="art" && !obj.srcElement.checked) {
        this.artSelection = false;
      }

      if(type==="furniture" && obj.srcElement.checked) {
        this.furnitureSelection = true;
      }
      if(type==="furniture" && !obj.srcElement.checked) {
        this.furnitureSelection = false;
      }

      if(type==="misc" && obj.srcElement.checked) {
        this.miscSelection = true;
      }
      if(type==="misc" && !obj.srcElement.checked) {
        this.miscSelection = false;
      }


      if(this.artSelection) {
        this.dataService.items = this.dataService.items.concat(this.dataService.artitems);
      }

      if(this.furnitureSelection) {
        this.dataService.items = this.dataService.items.concat(this.dataService.furnitureitems);
      }

      if(this.miscSelection) {
        this.dataService.items = this.dataService.items.concat(this.dataService.miscitems);
      }





      var obj2 = document.getElementById("dropdownCheckboxButton");
      obj2!.click();



    }


*/




}
