import { Component, ChangeDetectorRef } from '@angular/core';
import { OnInit } from '@angular/core';
//import { initFlowbite } from 'flowbite';
import { Router } from "@angular/router";
import { AccountService, ConfigService, DataService } from '../../src/app/_services';
import { environment } from '../environments/environment';
import { first } from 'rxjs';
import { initFlowbite, initDropdowns, initTabs, initTooltips, initAccordions, initCarousels, initCollapses, initDrawers, initModals, initPopovers } from 'flowbite';
import { Moment } from 'moment';
import * as moment from 'moment';

const homepage = `${environment.homepage}`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  account = this.accountService.accountValue;
  title = 'web-app';
  showSideBar = true;
  showTopBar = true;
  announcement = "Welcome!";
  currentDate = moment().format('ddd, MMMM D, YYYY');
  show = true;
  constructor(
      private router: Router,
      private accountService: AccountService,
      private configService: ConfigService,
      public dataService: DataService,
      private cd: ChangeDetectorRef

  )
    {
          //setTimeout(() => initFlowbite(), 1500);
    }

  ngOnInit(): void {
    initFlowbite()

    this.showSideBar = true;
    this.showTopBar = true;

    if (location.pathname ===  "/login") {
      this.showSideBar = false;
      this.showTopBar = false;

    } else if (location.pathname ===  "/myauction") {
      this.showSideBar = false;
      this.showTopBar = true;
    } else {

      if (this.account) {
        if (this.account.user.role==='user') {
          window.location.href = `${homepage}/myauction`;
        }
      }


    }




  }


  logout() {
    this.accountService.logout();
    window.location.href = `${environment.homepage}/login`;
  }

}
