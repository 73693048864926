import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { DropdownInterface } from 'flowbite';
const baseUrl = `${environment.apiUrl}`;


@Injectable()
export class DataService {
    currentItem:any = null;
    user: any;
    pto: any;
    configs: any[] = [];
    items: any[] = [];
    filtereditems: any[] = [];
    users: any;
    ptotypes: any[] = [];
    blockeddays: any[] = [];
    company: any;
    request: any;
    dropdown: DropdownInterface|any;

    date1 = new Date();

    currentyear = this.date1.getFullYear();
    dialogRef: any;

    config = {
      "id":0,
      "startdate":"",
      "enddate":"",
      "starttime":"",
      "endtime":"",
      "bidincrements": 0,
      "notification_bid_details": 0,
      "notification_bid_beaten": 0,
      "notification_item_won_lost": 1,
      "buynowtext":""
    }


    public constructor(
      private http: HttpClient
    ) {


    }

    public getConfig() {
      return this.http.get<any>(`${baseUrl}/config`);
    }


    getUsers() {
        return this.http.get<any>(`${baseUrl}/users`);
    }

}
