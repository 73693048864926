
<section class="bg-white dark:bg-gray-900">
  <button (click)="goBack()" class="bg-black text-white font-bold py-2 px-4 m-4 rounded">
    Back
  </button>

  <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:grid-cols-12">
      <div class="mr-auto place-self-center lg:col-span-7">
          <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">{{dataService.currentItem.title}}</h1>
          <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">{{dataService.currentItem.fulldescription}}</p>
         <button data-modal-target="bid-modal" data-modal-toggle="bid-modal" class="flex w-full items-center justify-center px-5 py-3 text-base font-medium text-center text-white border-gray-300 rounded-lg hover:ring-2 hover:ring-blue-900 bg-blue-700">
            Place Bid
          </button>

          <div id="bid-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

            <div class="w-full flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">


                <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-2 space-y-2 md:space-y-2 sm:p-8">


                        <!-- Modal header -->
                      <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          Place a bid
                        </h3>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="bid-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                      </div>

                      <form class="space-y-1 md:space-y-1" (ngSubmit)="placeBid()">
                        <div class="flex flex-col">

                          <div class="grid md:grid-cols-3 md:gap-6">
                            <div class="relative z-0 w-full mb-5 group">
                              <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Bid</label>
                              <label class="text-gray-900 text-sm rounded-lg  block w-full p-2.5 border-0">{{dataService.currentItem.currentbid | currency}}</label>
                            </div>
                            <div class="relative z-0 w-full mb-5 group">
                              <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Minimum Bid</label>
                              <label class="text-gray-900 text-sm rounded-lg  block w-full p-2.5 border-0">{{minbid | currency}}</label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bid amount</label>
                          <input [(ngModel)]="bidamount" type="number" name="value" id="value" class="bg-gray-50 border-2  {{bidamount > minbid ? 'border-green-300':'border-red-300'}} text-gray-900 text-sm rounded-lg  block w-full p-2.5" required="">
                        </div>
                        <div>
                          <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your current bid</label>
                          <label class="text-gray-900 text-sm rounded-lg  block w-full p-2.5 border-0">{{mybid.amount | currency}}</label>
                        </div>

                        <div>
                          <label for="value" class="block mb-2 text-sm font-medium text-red-600">{{errorMsg}}</label>

                        </div>

                        <div class="flex flex-col pt-5">
                          <div class="grid md:grid-cols-2 md:gap-6">
                            <div class="relative z-0 w-full mb-5 group">
                              <button class="flex w-full items-center justify-center px-5 py-3 text-base font-medium text-center text-white border-gray-300 rounded-lg hover:ring-2 hover:ring-blue-500 bg-blue-300" data-modal-hide="bid-modal">
                                Cancel
                              </button>
                            </div>
                            <div class="relative z-0 w-full mb-5 group">
                              <button class="flex w-full items-center justify-center px-5 py-3 text-base font-medium text-center text-white border-gray-300 rounded-lg hover:ring-2 hover:ring-blue-900 bg-blue-700">
                                Place Bid
                              </button>
                            </div>
                          </div>
                        </div>


                      </form>
                    </div>
                </div>
            </div>
          </div>

          <div id="buy-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

            <div class="w-full flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">


                <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-2 space-y-2 md:space-y-2 sm:p-8">


                        <!-- Modal header -->
                      <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          Buy Now
                        </h3>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="buy-modal">
                          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span class="sr-only">Close modal</span>
                        </button>
                      </div>
                      <form class="space-y-1 md:space-y-1" (ngSubmit)="placeBid()">
                        <div class="flex flex-col">

                          <div class="grid md:grid-cols-1 md:gap-6">
                            <div class="relative z-0 w-full mb-5 group">
                              <label class="text-gray-900 text-sm rounded-lg  block w-full p-2.5 border-0">{{dataService.config.buynowtext}}</label>
                            </div>
                            <div class="relative z-0 w-full mb-5 group">
                              <button (click)="buyNow()" class="flex w-full items-center justify-center px-5 py-3 text-base font-medium text-center text-blue-700 border-blue-900 border-2 rounded-lg hover:ring-2 hover:ring-blue-900 bg-blue-50">
                                Buy Now {{dataService.currentItem.buynow | currency}}!
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
          </div>



          <div class="mt-6 grow">
            <div class="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
              <div class="space-y-2" >
                <dl class="flex items-center justify-between gap-4 pt-2">
                  <dt class="text-base font-bold text-gray-900 dark:text-white">Item #</dt>
                  <dd class="text-base font-bold text-gray-900 dark:text-white">{{dataService.currentItem.itemid}}</dd>
                </dl>
                <dl class="flex items-center justify-between gap-4 border-b border-gray-200 pt-2 dark:border-gray-700">
                  <dt class="text-base font-bold text-gray-900 dark:text-white">Category</dt>
                  <dd class="text-base font-bold text-gray-900 dark:text-white">{{dataService.currentItem.category}}</dd>
                </dl>

                <dl class="flex items-center justify-between gap-4">
                  <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Current bid</dt>
                  <dd class="text-base font-medium text-gray-900 dark:text-white" *ngIf="dataService.currentItem.currentbid">{{dataService.currentItem.currentbid | currency}}</dd>
                  <dd class="text-base font-medium text-gray-900 dark:text-white" *ngIf="!dataService.currentItem.currentbid">- no bid - </dd>
                </dl>

                <dl class="flex items-center justify-between gap-4">
                  <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Your bid</dt>
                  <dd class="text-base font-medium ">
                    <span *ngIf="outbid">
                      <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">outbid</span>
                      <span class="text-red-800">{{mybid.amount | currency}}</span>
                    </span>
                    <span *ngIf="bestbid">
                      <span class="bg-green-300 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">top bid</span>
                      <span class="text-green-700">{{mybid.amount | currency}}</span>
                    </span>
                    <span *ngIf="nobid">
                      <span class="text-green-700">- no bid - </span>
                    </span>

                  </dd>
                </dl>


                <!-- BUY NOW HERE
                <div class="relative z-0 w-full mb-5 group">
                </div>
                -->
                <button data-modal-target="buy-modal" data-modal-toggle="buy-modal" class="{{dataService.currentItem.buynow > 0 ? '' : 'hidden'}} flex w-full items-center justify-center px-5 py-3 text-base font-medium text-center text-blue-700 border-blue-900 border-2 rounded-lg hover:ring-2 hover:ring-blue-900 bg-blue-50">
                  Buy Now {{dataService.currentItem.buynow | currency}}!
                </button>




                <dl class="flex items-center justify-between gap-4">
                  <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Starting bid</dt>
                  <dd class="text-base font-medium text-gray-900">{{dataService.currentItem.startingbid | currency}}</dd>
                </dl>

                <dl class="flex items-center justify-between gap-4">
                  <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Appraised value</dt>
                  <dd class="text-base font-medium text-gray-900">{{dataService.currentItem.value | currency}}</dd>
                </dl>

                <dl class="flex items-center justify-between gap-4">
                  <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Bid increments</dt>
                  <dd class="text-base font-medium text-gray-900 dark:text-white">{{dataService.config.bidincrements | currency}}</dd>
                </dl>
              </div>


            </div>
          </div>
      </div>
      <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img [src]="dataService.currentItem.image" alt="mockup">
      </div>
  </div>
</section>




<!--
<div class="flex flex-colum items-center h-full">
  <div class="justify-center px-5 pb-5 w-full">
    <img class="p-8 rounded-t-2xl w-96 h-96" [src]="dataService.currentItem.image" alt="product image" />
    <div class="px-5 pb-5">

        <div class="text-center text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{{dataService.currentItem.title}}</div>
        <div class="text-lg  tracking-tight text-gray-700">{{dataService.currentItem.fulldescription}}</div>
        <div class="mb-5 text-lg  tracking-tight text-gray-700">{{dataService.currentItem.pickupinstructions}}</div>

        <div class="text-sm  tracking-tight text-gray-700">Value: {{dataService.currentItem.value}}</div>
        <div class="text-sm  tracking-tight text-gray-700">Minimum bid: {{dataService.currentItem.minbid}}</div>
        <div class="text-sm  tracking-tight text-gray-700">Bid increments: {{dataService.currentItem.increments}}</div>

    </div>
  </div>
  <div class="justify-center pl-20 w-full h-full border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div class="flex justify-center px-5 pb-5">
      <div class="text-center text-4xl font-semibold tracking-tight text-gray-900 dark:text-white">{{dataService.currentItem.title}}</div>
    </div>
    <div class="flex items-center">
      <ul class="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
          <li>
            <svg class="float-left w-5 h-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"/>
            </svg>

            <a href="#" class="float-right text-gray-700 hover:underline pl-2" aria-current="page">Current Bid</a>
          </li>
          <li>
            <svg class="float-left w-5 h-5 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
            </svg>

            <a href="#" class="float-right text-gray-500 hover:underline pl-2" aria-current="page">Total Bids</a>
          </li>


      </ul>

  </div>
  <button  type="submit" class="w-full text-white bg-blue-700 bg-primary-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-700 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
  </div>
</div>
-->
