import { Component, OnInit } from '@angular/core';
import { AccountService, DataService } from '@app/_services';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-myauction',
  templateUrl: './myauction.component.html',
  styleUrls: ['./myauction.component.css']
})
export class MyAuctionComponent implements OnInit{
  account = this.accountService.accountValue;
  constructor(
    private accountService: AccountService,
    public dataService: DataService
  )
  {

  }

  ngOnInit(): void {
    initFlowbite();
    this.dataService.currentItem = null;


  }








}
