import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Account } from '../../app/_models';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '../../app/_services';

import { HttpClient, HttpHeaders } from "@angular/common/http";

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class UserService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private dataService: DataService
    ) {
      // @ts-ignore
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }


    public getUsers() {
      return this.http.get<any>(`${baseUrl}/users`);
    }



  }
