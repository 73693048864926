import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Account } from '@app/_models';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '.';

import { HttpClient, HttpHeaders } from "@angular/common/http";

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class ItemsService {

    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private dataService: DataService
    ) {
      // @ts-ignore
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    public getItems() {
        return this.http.get<any>(`${baseUrl}/items`);
    }

    public addItem(obj:any) {
      return this.http.post<any>(`${baseUrl}/items`, obj);
    }

    public updateItem(id:any, obj:any) {
      return this.http.put<any>(`${baseUrl}/items/${id}`, obj);
    }

    public uploaditem(image: any) {
      const formData: FormData = new FormData();
      formData.append('file', image);
      return this.http.post<any>(`${baseUrl}/s3/upload`, formData);
    }

    public deleteItem(id:any) {
      return this.http.delete<any>(`${baseUrl}/items/${id}`);
    }

    public purchase(obj:any) {
      return this.http.post<any>(`${baseUrl}/purchases`, obj);
    }



}
