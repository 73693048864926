import { Component, OnInit } from '@angular/core';
import { AccountService, ConfigService, DataService } from '@app/_services';
import { initFlowbite } from 'flowbite';
import { Datepicker } from 'flowbite';
import type { DatepickerOptions, DatepickerInterface, ModalOptions, ModalInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import Swal from 'sweetalert2';
import { Modal } from 'flowbite';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  //datepicker!: DatepickerInterface;
  //datepicker2!: DatepickerInterface;
  newCategory="";
  category = {
    "id":0,
    "category":""
  }
  validationError="";

  account = this.accountService.accountValue;
  categories:any = [];

  constructor(
    private accountService: AccountService,
    private configService: ConfigService,
    public dataService: DataService
  )
  {
    //setTimeout(() => initFlowbite(), 1500);
  }

  ngOnInit(): void {
    setTimeout(() => initFlowbite(), 1500);


    // set the target element of the input field or div
    const $datepickerEl: HTMLInputElement = document.getElementById('startdate-datepicker') as HTMLInputElement;
    const $datepickerEl2: HTMLInputElement = document.getElementById('enddate-datepicker') as HTMLInputElement;

    const options: DatepickerOptions = {
      defaultDatepickerId: null,
      autohide: true,
      format: 'mm/dd/yyyy',
      maxDate: null,
      minDate: null,
      orientation: 'bottom',
      buttons: true,
      autoSelectToday: 1,
      rangePicker: false,
      onShow: () => { console.log(1)},
      onHide: () => { console.log(2)}
    };

    const options2: DatepickerOptions = {
      defaultDatepickerId: null,
      autohide: true,
      format: 'mm/dd/yyyy',
      maxDate: null,
      minDate: null,
      orientation: 'bottom',
      buttons: true,
      autoSelectToday: 1,
      rangePicker: false,
      onShow: () => { console.log(1)},
      onHide: () => { console.log(2)}
    };

    // instance options object
    const instanceOptions: InstanceOptions = {
      id: 'startdate-datepicker-example',
      override: true
    };

    // instance options object
    const instanceOptions2: InstanceOptions = {
      id: 'enddate-datepicker-example',
      override: true
    };

    const datepicker = new Datepicker($datepickerEl, options, instanceOptions);
    const datepicker2 = new Datepicker($datepickerEl2, options2, instanceOptions2);

    console.log(this.account);

    this.configService.getConfig().pipe()
    .subscribe({
        next: (data:any) => {
          this.dataService.config = data;

          console.log(data);

          datepicker.setDate(this.dataService.config.startdate);
          datepicker2.setDate(this.dataService.config.enddate);

        }
      })

    this.configService.getCategories().pipe()
    .subscribe({
        next: (data:any) => {
          this.categories = data; //data.map((c: { category: any; }) => c.category);

        }
      })


  }


  addCategory() {

    if (this.newCategory=="") {
      this.validationError = "Category is required"
      return;
    }

    var obj = {
      "category":this.newCategory
    }

    this.configService.addCategory(obj).pipe()
    .subscribe({
        next: (data:any) => {
          console.log(data);
        window.location.reload();
        }
      })
  }

  updateCategory() {

    if (this.category.category=="") {
      this.validationError = "Category is required"
      return;
    }

    var obj = {
      "category":this.category.category
    }

    this.configService.updateCategory(this.category.id, obj).pipe()
    .subscribe({
        next: (data:any) => {
          console.log(data);
        window.location.reload();
        }
      })
  }

  editCategory(obj:any) {
    this.category = obj;
    return;

    // set the modal menu element
    const $modalElement: any = document.querySelector('#modalEdit');

    // options with default values
    const modalOptions: ModalOptions = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
          'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
          console.log('modal is hidden');
      },
      onShow: () => {
          console.log('modal is shown');
      },
      onToggle: () => {
          console.log('modal has been toggled');
      },
    };
    // instance options object
    const instanceOptions: InstanceOptions = {
      id: 'modalEdit',
      override: true
    };

    const modal: ModalInterface = new Modal($modalElement, modalOptions, instanceOptions);

    modal.show();

  }

  deleteCategory(obj:any) {

    console.log(obj);

    if (obj.count > 0) {
      Swal.fire({
        title: "Alert",
        text: "You cannot delete a category with existing items!",
        icon: "error"
      });
      return;
    }


    Swal.fire({
      title: 'Are you sure you want to delete this category?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.configService.deleteCategory(obj.id).pipe()
        .subscribe({
            next: (data:any) => {
              window.location.reload();
            }
          })


      } else  {
        //Swal.fire('Changes are not saved', '', 'info')

      }
    })


  }

  saveSettings() {
    debugger;
    console.log(this.dataService.config);

    const $datepickerEl: HTMLInputElement = document.getElementById('startdate-datepicker') as HTMLInputElement;
    const $datepickerEl2: HTMLInputElement = document.getElementById('enddate-datepicker') as HTMLInputElement;
    const datepicker = new Datepicker($datepickerEl, {}, {});
    const datepicker2 = new Datepicker($datepickerEl2, {}, {});


    var sDate = new Date(datepicker.getDate().toString()).toISOString();
    var sDate2 = new Date(datepicker2.getDate().toString()).toISOString();


    var obj = {
      "bidincrements":this.dataService.config.bidincrements,
      "startdate": sDate.slice(0, 19).replace('T', ' '),
      "enddate": sDate2.slice(0, 19).replace('T', ' '),
      "notification_bid_details": this.dataService.config.notification_bid_details,
      "notification_bid_beaten": this.dataService.config.notification_bid_beaten,
      "notification_item_won_lost": this.dataService.config.notification_item_won_lost
    }


    this.configService.updateConfig(obj).pipe()
    .subscribe({
        next: (data:any) => {
          console.log(data);
        window.location.reload();
        }
      })
  }






}

function getFormattedDate(date: any) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}
