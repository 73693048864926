import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "../../environments/environment";
import { AccountService } from "../../app/_services";

const homepage = `${environment.homepage}`;

@Injectable({ providedIn: "root" })
export class AuthGuard  {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.accountService.accountValue;
        if (account) {
          if (account.user.role==='user' && route.url[0].path != 'myauction') {
            window.location.href = `${homepage}/myauction`;
          }
            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        //this.router.navigate(["/account/login"], { queryParams: { returnUrl: state.url }});
        window.location.href = `${homepage}/login`;

        return false;
    }
}
