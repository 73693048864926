import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common'
import { AccountService, UserService, ConfigService, DataService } from '@app/_services';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError, throwError } from 'rxjs';
import { initFlowbite } from 'flowbite';
import { Modal } from 'flowbite';
import type { ModalOptions, ModalInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import {NgxCroppedEvent, NgxPhotoEditorService} from "ngx-photo-editor";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit{

  account = this.accountService.accountValue;


  modal: ModalInterface | undefined;

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    public dataService: DataService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private router: Router,
    private service: NgxPhotoEditorService
  )
  {

  }

  ngOnInit(): void {
    initFlowbite();


  }


}
