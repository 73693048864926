import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Account } from '../../app/_models';
import { CookieService } from 'ngx-cookie-service';
import { DataService, ConfigService } from '../../app/_services';

import { HttpClient, HttpHeaders } from "@angular/common/http";

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private dataService: DataService,
        public configService: ConfigService
    ) {
      // @ts-ignore
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }



    login(username: string, password: string) {
      this.cookieService.delete('directory-auth-token'); // return req.headers["rr-auth-cookie"];
      this.cookieService.delete('directory-refresh-token'); // return req.headers["rr-auth-cookie"];

      debugger;
      return this.http.post<any>(`${baseUrl}/login`, { username, password })
            .pipe(map(account => {
                this.accountSubject.next(account);


                this.cookieService.set('directory-auth-token', account.jwtToken, { expires: 2, sameSite: 'Lax' }); // return req.headers["rr-auth-cookie"];
                this.cookieService.set('directory-refresh-token', account.jwtRefreshToken, { expires: 365 }); // return req.headers["rr-auth-cookie"];
                this.startRefreshTokenTimer();
                return account;
            }));

    }

    logout() {
        var refreshtoken = this. cookieService.get('directory-refresh-token');


        sessionStorage.clear();
        this.stopRefreshTokenTimer();
        this.cookieService.delete('directory-auth-token'); // return req.headers["rr-auth-cookie"];
        this.cookieService.delete('directory-refresh-token'); // return req.headers["rr-auth-cookie"];

        this.http.post<any>(`${baseUrl}/revoketoken`, {refreshtoken})

        //.pipe(first())
        .subscribe({
            next: (data) => {

              // @ts-ignore
              this.accountSubject.next(null);


              this.router.navigate(['/login']);
            },
            error: error => {
                //this.alertService.error(error);
                //this.loading = false;
                console.log(error);
            }
        });



    }

    refreshToken() {

            var token = this. cookieService.get('directory-auth-token');
            var refreshtoken = this. cookieService.get('directory-refresh-token');

            return this.http.post<any>(`${baseUrl}/refreshtoken`, {refreshtoken}, { headers: this.getSecureHeaders(token) } )
                .pipe(map((account) => {

                    if (account) {

                        this.accountSubject.next(account);

                        this.startRefreshTokenTimer();
                        return account;
                    } else {
                        this.stopRefreshTokenTimer();
                        // @ts-ignore
                        this.accountSubject.next(null);
                        this.router.navigate(['/login']);
                    }
                }));

    }

    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
        /*Following code is for api register call*/

        // return this.registerService.register(account)
        //     .pipe(map(account => {

        //         return account;

        //     }));
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email`, { token });
    }

    forgotPassword(email: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { email });
    }

    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token: token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }


    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/users/${id}`);
    }

    create(params: any) {
        return this.http.post(`${baseUrl}/users`, params);
    }

    update(id: any, params: any) {

        return this.http.put(`${baseUrl}/users/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.accountValue.id)
                    this.logout();
            }));
    }


  getSecureHeaders(token: any) {

    let headers = new HttpHeaders().set("Content-Type", "application/json").set("Authorization", token);

    //headers.append("RefreshToken", Config.refreshToken);

    return headers;
  }

    // helper methods

    private refreshTokenTimeout: string | number | NodeJS.Timeout | undefined;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => { this.refreshToken().subscribe() }, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
