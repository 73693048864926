import { Inject } from '@angular/core';
import { AccountService, DataService, UserService } from '@app/_services';
import { environment } from '../../environments/environment';

export function appInitializer(accountService: AccountService, userService: UserService,  dataService: DataService) {



    return () => new Promise<void>(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        // need to check the route in case this is a reset password link

        debugger;
        if (location.hash.split('?')[0] ===  "account/reset-password") {
          resolve();
        } else if (location.pathname ===  "/login") {
          resolve();
        //} else if (location.pathname ===  "") {
          //window.location.href = `${environment.homepage}/login`;
        } else {
          accountService.refreshToken().subscribe(
            { next: (data:any) => {
                  resolve();
                },
                error: error => {
                    console.log(error);
                    accountService.logout();
                    window.location.href = `${environment.homepage}/login`;
                    //window.location.href = `${environment.homepage}/#/login`;
                }
              },
          )
        }
      });
}


