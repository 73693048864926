import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import { AccountService } from '@app/_services';
import { first } from 'rxjs';
import { initFlowbite } from 'flowbite';
import { environment } from '../../environments/environment';

const homepage = `${environment.homepage}`;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,

) {

}
  ngOnInit(): void {
    initFlowbite();
  }

  login() {
//    initFlowbite();
    debugger;
    var username:any = document.getElementById('role');

    this.accountService.login(username.value, '')
            .pipe(first())
            .subscribe({
                next: (data) => {
                  console.log(data);
                    // get return url from query parameters or default to home page

                    //var returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
                    //this.router.navigateByUrl(returnUrl);
                    window.location.href = `${homepage}/home`;
                },
                error: error => {
                    //this.alertService.error(error);
                    //this.loading = false;
                    console.log(error);
                }
            });
  }
}
