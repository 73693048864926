import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InventoryComponent } from './home/inventory/inventory.component';
import { LoginComponent } from './account/login.component';
import { SettingsComponent } from './settings/settings.component';
import { MyAuctionComponent } from './home/myauction.component';
import { AuthGuard } from "./_helpers";
import { BiddersComponent } from './home/bidders/bidders.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch : 'full'  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard]},
  { path: 'bidders', component: BiddersComponent, canActivate: [AuthGuard]},
  { path: 'myauction', component: MyAuctionComponent, canActivate: [AuthGuard]},
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent},

    // otherwise redirect to home
    //{ path: '**', redirectTo: 'login', pathMatch: 'full' }

];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
