import { NgModule, APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { InventoryComponent } from './home/inventory/inventory.component';
import { BiddersComponent } from './home/bidders/bidders.component';
import { MyAuctionComponent } from './home/myauction.component';
import { SearchComponent } from './_components/search.component';
import { LinksComponent } from './_components/links.component';
import { ItemsComponent } from './_components/items.component';
import { ItemComponent } from './_components/item.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountService, DataService, ConfigService } from './_services';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { appInitializer } from './_helpers';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { NgxPhotoEditorModule } from "ngx-photo-editor";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
//import { MatSliderModule } from '@angular/material/slider';
//import { MatFormFieldModule } from '@angular/material/form-field';
//import { MatInputModule } from '@angular/material/input';
//import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatDialogModule } from '@angular/material/dialog';
//import { MatSelectModule } from '@angular/material/select';
//import { MatNativeDateModule } from '@angular/material/core';
//import { MatListModule } from '@angular/material/list';
//import { MatRadioModule } from '@angular/material/radio';
//import { MatCheckboxModule } from '@angular/material/checkbox';
//import { MatTabsModule } from '@angular/material/tabs';
//import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
//import { ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';

@NgModule({
      //=> Basic usage (forRoot can also take options, see the wiki)
  declarations: [
    AppComponent,
    HomeComponent,
    InventoryComponent,
    BiddersComponent,
    MyAuctionComponent,
    SearchComponent,
    LinksComponent,
    ItemsComponent,
    ItemComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  //  MatSliderModule,
    //MatFormFieldModule,
    //MatDialogModule,
    //MatSelectModule,
    //MatSnackBarModule,
    //MatListModule,
    //MatCheckboxModule,
    //MatRadioModule,
    //MatInputModule,
    //MatTabsModule,
    //MatDatepickerModule,
    //MatNativeDateModule,
    //MatProgressSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPhotoEditorModule,
    SweetAlert2Module.forRoot(),

    //HttpClientXsrfModule.withOptions({
      //  cookieName: 'Report-Rover-Cookie',
        //headerName: 'Report-Rover-Header'
    //}),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService, ConfigService, DataService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AccountService,
    DataService,
    ConfigService,
    [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  ],
  bootstrap: [AppComponent]

})
export class AppModule {

 }
