
<div class="grid m-4 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-4 bg-white dark:bg-gray-800">
  <figure (click)="openitem(item)" *ngFor="let item of dataService.filtereditems" class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">

    <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
      <!--<div class="relative float-right -mt-6 -mr-16">
        <svg class=" w-8 h-8 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"/>
        </svg>
      </div>-->
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">{{item.title}}</h3>
      <p class="my-2 h-12 w-60">{{item.description}}</p>
      <p class="my-0">{{item.itemid}}</p>
    </blockquote>
    <img class="w-48 h-48" [src]="item.image" alt="item image">
    <figcaption class="flex w-full my-2">
        <div class="font-medium dark:text-white w-full my-2">
            <div class="text-sm text-gray-500 dark:text-gray-300 float-left">Bids: {{item.counter}}</div>
            <div class="text-sm text-gray-500 dark:text-gray-300 float-right">Current bid: {{item.currentbid}}</div>
          </div>

    </figcaption>


  </figure>
</div>




