import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/_services';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit{
  account = this.accountService.accountValue;
  constructor(private accountService: AccountService
  )
  {

  }

  ngOnInit(): void {
    //initFlowbite();
  }













}
