

<div class="relative overflow-x-auto shadow-md sm:rounded-lg p-5">

  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Name
              </th>
              <th scope="col" class="px-6 py-3">
                Email
              </th>
              <th scope="col" class="px-6 py-3">
                Bids
              </th>
              <th scope="col" class="px-6 py-3">
                Items Bid
              </th>
              <th scope="col" class="px-6 py-3">
                items Won
              </th>
              <th scope="col" class="px-6 py-3">
                Actions
              </th>
            </tr>
      </thead>
      <tbody *ngIf="filtereditems.length > 0">
          <tr *ngFor="let item of bidders" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

              <td class="px-6 py-4">
                {{item.firstname}} {{item.lastname}}
              </td>
              <td class="px-6 py-4">
                {{item.email}}
              </td>
              <td class="px-6 py-4">
                {{item.bids}}
              </td>
              <td class="px-6 py-4">
                {{item.items}}
              </td>
              <td class="px-6 py-4">
                0
              </td>

          </tr>
      </tbody>

  </table>
</div>
