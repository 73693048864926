

<div class="relative overflow-x-auto shadow-md sm:rounded-lg p-5">
  <div class="grid grid-cols-2 gap-[80%] space-y-4 sm:space-y-0 items-center justify-between pb-4">
      <div class="w-full">
          <button id="dropdownButton" data-dropdown-toggle="dropdownMenu" class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
              <svg class="w-3 h-3 text-gray-500 dark:text-gray-400 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z"/>
              </svg>
              {{currentCategory}}
              <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
              </svg>
          </button>
          <!-- Dropdown menu -->
          <div id="dropdownMenu" class="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(522.5px, 3847.5px, 0px);">
              <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownButton">
                <li>
                  <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <input (change)="filter('All Categories', $event)"  [checked]="currentCategory=='All Categories'" id="filter-radio-example-1" type="radio" value="" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label for="filter-radio-example-1" class="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">All Categories</label>
                  </div>
                </li>
                <li *ngFor="let cat of categories" >
                      <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                          <input (change)="filter(cat.category, $event)"  [checked]="cat.category == currentCategory" id="filter-radio-example-1" type="radio" value="" name="filter-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <label for="filter-radio-example-1" class="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{cat.category}}</label>
                      </div>
                  </li>
              </ul>
          </div>
      </div>

      <div class="w-full z-0">

              <!-- New Category Modal toggle -->
              <button data-modal-target="add-modal"  data-modal-toggle="add-modal" class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 z-0" type="button">
                Add Item
              </button>
      </div>
      <div class="relative">
              <!-- Inventory add modal -->
              <div id="add-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

                  <div class="w-full flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">


                      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                          <div class="p-2 space-y-2 md:space-y-2 sm:p-8">


                              <!-- Modal header -->
                            <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t dark:border-gray-600">
                              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                  New Item
                              </h3>
                              <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="add-modal">
                                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                  </svg>
                                  <span class="sr-only">Close modal</span>
                              </button>
                            </div>
                              <form class="space-y-1 md:space-y-1" (ngSubmit)="addInventory()">
                                  <div>
                                      <label for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Id</label>
                                      <input [(ngModel)]="item.itemid" type="text" name="itemid" id="itemid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required="">
                                  </div>
                                  <div>
                                    <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                                    <select id="category"  (change)="onChange($event)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                      <option value="0"></option>
                                      <option *ngFor="let item of categories" value="{{item.id}}">{{item.category}}</option>

                                    </select>
                                  </div>

                                  <div>
                                      <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                                      <input [(ngModel)]="item.title" type="text" name="title" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                  </div>
                                  <div>
                                      <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                      <textarea name="description" id="description" [(ngModel)]="item.description"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""></textarea>
                                  </div>

                                  <div class="flex flex-col pt-5">
                                    <div class="grid md:grid-cols-3 md:gap-6">
                                      <div>
                                        <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Value</label>
                                        <input [(ngModel)]="item.value" type="number" name="value" id="value" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                      </div>
                                      <div>
                                        <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Starting Bid</label>
                                        <input [(ngModel)]="item.startingbid" type="number" name="startingbid" id="startingbid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                      </div>
                                      <div>
                                        <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Buy Now</label>
                                        <input [(ngModel)]="item.buynow" type="number" name="buynow" id="buynow" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                      </div>
                                    </div>
                                  </div>

                                  <div class="flex items-center space-x-6">
                                    <div class="shrink-0">
                                      <img id='preview_img' class="h-16 w-16 object-cover" [src]=item.image alt="Current photo" />
                                    </div>
                                    <label class="block">
                                      <span class="sr-only">Choose image</span>
                                      <input type="file" (change)="loadFile($event)" #fileupload class="block w-full text-sm text-slate-500
                                        file:mr-4 file:py-2 file:px-4
                                         file:border-0
                                        file:text-sm file:font-semibold
                                        file:bg-violet-50 file:text-violet-700
                                        hover:file:bg-violet-100
                                      "/>
                                    </label>

                                  </div>
                                  <div class="flex items-start w-full">
                                    <div class="text-red-500"> {{validationError}}</div>
                                  </div>
                                  <div class="flex items-start w-full">
                                    <button type="submit" class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                      Save
                                    </button>
                                    <button type="button" data-modal-hide="add-modal" class="ml-2 text-white inline-flex items-center bg-gray-400 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                      Cancel
                                    </button>
                                  </div>
                             </form>
                          </div>
                      </div>
                  </div>

              </div>
              <!-- Inventory Edit modal -->
              <div id="edit-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

                <div class="w-full flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

                    <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div class="p-4 space-y-2 md:space-y-2 sm:p-8">
                           <!-- Modal header -->
                           <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Edit Item
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="edit-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                          </div>
                            <form class="space-y- md:space-y-2" (ngSubmit)="saveInventory()">
                                <div>
                                    <label for="itemid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Item Id</label>
                                    <input [(ngModel)]="item.itemid" type="text" name="itemid" id="itemid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required="">
                                </div>
                                <div>
                                  <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                                  <select id="category" (change)="onChange($event)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value=0 [selected]="item.categoryid===0"></option>
                                    <option *ngFor="let it of categories" value={{it.id}} [selected]="item.categoryid===it.id">{{it.category}}</option>

                                  </select>
                                </div>

                                <div>
                                    <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                                    <input [(ngModel)]="item.title" type="text" name="title" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                </div>
                                <div>
                                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                    <textarea name="description" id="description" [(ngModel)]="item.description"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""></textarea>
                                </div>

                                <div class="flex flex-col pt-5">
                                  <div class="grid md:grid-cols-3 md:gap-6">
                                    <div>
                                      <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Value</label>
                                      <input [(ngModel)]="item.value" type="number" name="value" id="value" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                    </div>
                                    <div>
                                      <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Starting Bid</label>
                                      <input [(ngModel)]="item.startingbid" type="number" name="startingbid" id="startingbid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                    </div>
                                    <div>
                                      <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Buy Now</label>
                                      <input [(ngModel)]="item.buynow" type="number" name="buynow" id="buynow" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                    </div>
                                  </div>
                                </div>

                                <div class="flex items-center space-x-6">
                                  <div class="shrink-0">
                                    <img id='preview_img' class="h-16 w-16 object-cover" [src]=item.image alt="Current photo" />
                                  </div>
                                  <label class="block">
                                    <span class="sr-only">Choose profile photo</span>
                                    <input type="file" (change)="loadFile($event)" #fileupload class="block w-full text-sm text-slate-500
                                      file:mr-4 file:py-2 file:px-4
                                       file:border-0
                                      file:text-sm file:font-semibold
                                      file:bg-violet-50 file:text-violet-700
                                      hover:file:bg-violet-100
                                    "/>
                                  </label>

                                </div>
                                <div class="flex items-start w-full">
                                  <div class="text-red-500"> {{validationError}}</div>
                                </div>
                                <div class="flex items-start w-full">
                                  <button type="submit" class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Save
                                  </button>
                                  <button data-modal-hide="edit-modal" type="button" class="ml-2 text-white inline-flex items-center bg-gray-400 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Cancel
                                  </button>
                                </div>
                           </form>
                        </div>
                    </div>
                </div>

            </div>
      </div>
  </div>
  <div class="relative">
    <div class="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
      <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
    </div>
    <input type="text" id="table-search" class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
  </div>

  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
              <th scope="col" class="p-4">
                  <div class="flex items-center">
                      <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="checkbox-all-search" class="sr-only">checkbox</label>
                  </div>
              </th>
              <th scope="col" class="px-6 py-3">
                  Item Id
              </th>
              <th scope="col" class="px-6 py-3">
                Category
            </th>
              <th scope="col" class="px-6 py-3">
                  Title
              </th>
              <th scope="col" class="px-6 py-3">
                  Description
              </th>
              <th scope="col" class="px-6 py-3">
                Value
              </th>
              <th scope="col" class="px-6 py-3">
                Starting Bid
              </th>
              <th scope="col" class="px-6 py-3">
                Buy Now
              </th>
              <th scope="col" class="px-6 py-3">
                  Image
              </th>
              <th scope="col" class="px-6 py-3">
                  Actions
              </th>
          </tr>
      </thead>
      <tbody *ngIf="filtereditems.length > 0">
          <tr *ngFor="let item of filtereditems" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td class="w-4 p-4">
                  <div class="flex items-center">
                      <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                  </div>
              </td>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{item.itemid}}
              </th>
              <td class="px-6 py-4">
                {{item.category}}
              </td>
              <td class="px-6 py-4">
                  {{item.title}}
              </td>
              <td class="px-6 py-4">
                  {{item.description}}
              </td>
              <td class="px-6 py-4">
                {{item.value | currency}}
              </td>
              <td class="px-6 py-4">
                {{item.startingbid | currency}}
              </td>
              <td class="px-6 py-4">
                {{item.buynow | currency}}
              </td>
              <td class="px-6 py-4">
                <img src="{{item.image}}" width="50" />
              </td>
              <td class="px-6 py-4">
                  <button data-modal-target="edit-modal" data-modal-toggle="edit-modal" (click)="editInventory(item)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</button>
                  <button (click)="deleteInventory(item)" class="pl-6 font-medium text-red-600 dark:text-blue-500 hover:underline">Delete</button>
              </td>
          </tr>
      </tbody>
      <tbody *ngIf="filtereditems.length == 0">
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 h-16">
            <td class="col-span-8 h-16">
              No items for this category
            </td>
        </tr>
      </tbody>
  </table>
</div>
