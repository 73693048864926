import { Component, OnInit } from '@angular/core';
import { AccountService, ItemsService, DataService, BidsService } from '@app/_services';
import { initFlowbite } from 'flowbite';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit{
  account = this.accountService.accountValue;

  mybid = {
    "amount": 0
  };
  minbid = this.dataService.currentItem.currentbid + this.dataService.config.bidincrements;



  bidamount = 0.00;
  bestbid = false;
  outbid = false;
  nobid = true;

  errorMsg = "";

  constructor(
    private accountService: AccountService,
    public dataService: DataService,
    public bidsService: BidsService,
    public itemsService: ItemsService
  )
  {

  }

  ngOnInit(): void {
    initFlowbite();

    if (this.minbid < this.dataService.currentItem.startingbid) {
      this.minbid = this.dataService.currentItem. startingbid;
    }

    this.bidsService.getMyBids(this.account.user.id,this.dataService.currentItem.id).pipe()
    .subscribe({
        next: (data:any) => {
          this.mybid = data[0];
          this.nobid = false;
          if (this.mybid.amount > 0) {
            if (this.mybid.amount == this.dataService.currentItem.currentbid) {
              this.bestbid = true;
            } else {
              this.outbid = true;
            }
          } else {
            this.nobid = false;
          }
        }
      })


  }

  buyNow() {
    var body = {
      "user": this.account.user.id,
      "item": this.dataService.currentItem.id,
      "amount": this.dataService.currentItem.buynow,
      "purchasetype":"buynow"
    }
    this.itemsService.purchase(body).pipe()
    .subscribe({
        next: (data:any) => {
          console.log(data);
          this.dataService.currentItem=null;
        }
      })

      var obj = {
        "status": "purchased"
      }

      this.itemsService.updateItem(this.dataService.currentItem.id, obj).pipe()
      .subscribe({
          next: (data:any) => {
          }
        })
  }

  placeBid() {

    this.errorMsg = "";
    if (this.bidamount < this.minbid) {

      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

      this.errorMsg = `Minimum bid is ${USDollar.format(this.minbid)}`;
      return
    }

    var body = {
      "user": this.account.user.id,
      "item": this.dataService.currentItem.id,
      "amount": this.bidamount
    }
    this.bidsService.placeBid(body).pipe()
    .subscribe({
        next: (data:any) => {
          console.log(data);
          this.dataService.currentItem=null;
        }
      })

    var obj = {
      "currentbid": this.bidamount
    }

    this.itemsService.updateItem(this.dataService.currentItem.id, obj).pipe()
    .subscribe({
        next: (data:any) => {
        }
      })


  }

  goBack(): void {
    this.dataService.currentItem=null;
  }


}
